import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "PCB Design Tips",
  "description": "Basics on how to design PCB for safe and efficient processing, assembly, and performance.",
  "author": "Nadya Lukman",
  "categories": ["electronics"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["Components", "PCB-Layers", "Tolerances-Clearance", "CAD-Aperture-Files"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "component-placement"
    }}>{`Component Placement`}</h2>
    <h3 {...{
      "id": "general-rule-for-component-placement"
    }}>{`General rule for component placement:`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Through-hole (THT) components with polarity must be placed in the same direction, as neatly as possible. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If positioning SMD components in the same direction is not possible, just organize them in the same X or Y direction. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Leave at least 0.5mm in between SMD components, to locate heat sinks. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Thermosensors, such as electrolytic capacitors or crystal oscillators, should be kept far away from high heat elements; and they should be placed on the windward side. `}</p>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "32.43243243243243%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAC4jAAAuIwF4pT92AAABaUlEQVQY02P4DwP//v//8/ff////Lz/5sODInXVnH12/eevKpQvXL57btOfIvKP3lh2/t+LUg2UnHqw5/XDB0Xt7Lj9hgOj8++//v38gnYuO3ZXOXc4QsUg4Z83JO8+evPl059nrmL4NRfN2Tly916J6NXvaCoboJZK5q47eeArSDLHwy88/FcuP2+X2501YnjlzJ0P8csnCtaoVm2JnH3br3efSd8y5a5d6+RrWlGUq5RuvP3n7//9/hr9gC28+/2Dasku9cKla8XKp0o2aVRvDerfkzdimXLK6Yv6+8P7l1bMaGBJWMMQu1a3dfP/Ve4iVIJsXHL3LlrJSt2R53fxtplUredKWeLZvXLx5r2j+WpPieUaF80UTJqeU5zFELbJu2/HywxewN0FWMqw+ede4aZtp8w7vvt3uPbvs2ne4du/KmHvQs3ePUdMO794dFq07Ldv3aDQcDJuy//GLV39///r27TskpADPHPBNer5DQQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/efd1abb882188645dfd3b1c558a6bde0/652f5/pcb-design-component-placement-01.webp 259w", "/static/efd1abb882188645dfd3b1c558a6bde0/c29d2/pcb-design-component-placement-01.webp 518w", "/static/efd1abb882188645dfd3b1c558a6bde0/e88ff/pcb-design-component-placement-01.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/efd1abb882188645dfd3b1c558a6bde0/a2ead/pcb-design-component-placement-01.png 259w", "/static/efd1abb882188645dfd3b1c558a6bde0/6b9fd/pcb-design-component-placement-01.png 518w", "/static/efd1abb882188645dfd3b1c558a6bde0/0a47e/pcb-design-component-placement-01.png 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/efd1abb882188645dfd3b1c558a6bde0/0a47e/pcb-design-component-placement-01.png",
            "alt": "Placement of Thermosensors",
            "title": "Placement of Thermosensors",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Components with taller figures should be placed behind the shorter components in the direction of the least wind resistance, to prevent air duct from being blocked. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Avoid touching or stacking of components to provide space for component installation. `}</p>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "79.53667953667953%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAC4jAAAuIwF4pT92AAACRElEQVQoz22Ty27TQBSGs+U5eAU2vAO8AEuWbBFLFkhsgB07SqtekoAqgUAtvailVWh6QWmLenFSoEmr3GzHduwZjx1nrgfZidOI9Ncs5ozmO2fO/DMZSEQptSzLNM2AkEEohIBUSqnRFAB8EggpASADY+ISzOlHxuv7YVcXCv6T4EwBoK23zed3nU/PbmDBmY9cxnn3zT376R2OzUHFYVIaKU79rumgAC0+QY8zZOrhELZxeHZtbO7/epf/vL44tb80c9m0ri2fCzmAT2t6bq2YXynkN0tfZ15tvHiwOftS00kMV028fWFsVfSN8+b3KtmoBoVLZ+eyQ/kQrpro+Mo+a7hndeewjo8NcdQMtYaTgYnWADiABCWVFKAmWh9TDEdcdFqtdn6hnZtv5xZa2YX2h2xr9r1d0aIEVomEEKNrl8lKDPcqWqt0QAEYY4zzeDAmAbxaFR+VYjgxptfrIUywT6I+u6lMdgpO8UeScNjkYLd3fuouf4nDxPMoirDdFhFxzQYJwhT+uWfv707CqFL21ldHMCFBaNeB+ti46rreEA4O9py94m2w5q0uj0IphIcD39ExCRhjlNIBvGvv3gJ7F2V37dsojJ0QQv9d6jp2mCiFizuDhwZSxSYl5/TK5+7K0jjMOTc7HZK8/7Tnk2Pn6DBmZbxLAojEEvT3DypsT8KU0iHsI2RWNKdeNz5m29lZPT+vZ+eM3JwxN22VtSg5ghr7XlbtRPDUqn6/b3Y6YRBIjCTyJELp8GRaYVwqwqNs/wBfKnocJLjC0AAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/55044cdf30a6086504294a56bb82cfaf/652f5/pcb-design-component-placement-02.webp 259w", "/static/55044cdf30a6086504294a56bb82cfaf/c29d2/pcb-design-component-placement-02.webp 518w", "/static/55044cdf30a6086504294a56bb82cfaf/e88ff/pcb-design-component-placement-02.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/55044cdf30a6086504294a56bb82cfaf/a2ead/pcb-design-component-placement-02.png 259w", "/static/55044cdf30a6086504294a56bb82cfaf/6b9fd/pcb-design-component-placement-02.png 518w", "/static/55044cdf30a6086504294a56bb82cfaf/0a47e/pcb-design-component-placement-02.png 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/55044cdf30a6086504294a56bb82cfaf/0a47e/pcb-design-component-placement-02.png",
            "alt": "Components in contact",
            "title": "Components in contact",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <ul>
      <li parentName="ul">{`High heatload components, such as power inductors or power regulators, shoud not be placed to get too close to the technology edge (or panel edge), and the distance to the edge must be above 20mm to avoid uneven temperature of reflow oven guide rail that may affect soldering. `}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "34.36293436293437%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAABOUlEQVQY03XQO0/CUBjG8W4yaAiCSLmoIXExRFPr7qiL38FVE4QgcA4l0QTbuLgYHR2MQb+Gg6NuJo7COT3nVWNslcvgpX0NsBgDT37rf3kURETvBxHbjdumqTNL55bOTZ2by6xHY6bGDzReW2TVOVbLMEtrVtPyMouICvo+In51XXG4InMBKIWhFPpjsqcYkiQqTzekkYJyhGUDT+ebg9jzEV+utmQ+ANUkVGL/GSqUw7K20G2/P5+s2YWJRyPtsvt+jOjenImdMTDiQKeHIDEoRoGmXuvbYj8jckF5fdH5xg/XUTqNO0YSgqiCJgVNDEHioqTK4rTIBXl2nNfznz62XAcAFPt4nRdCtjFr0+RIJCEqM2w3wo9W3xzH8/zBUwpYS1AOA1WBxkZTgUzxvfmWfOhFvuf39wvdaf3OVCgQnwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/1a7d83f66beadd476cb5e1f0939a74bb/652f5/pcb-design-component-placement-03.webp 259w", "/static/1a7d83f66beadd476cb5e1f0939a74bb/c29d2/pcb-design-component-placement-03.webp 518w", "/static/1a7d83f66beadd476cb5e1f0939a74bb/e88ff/pcb-design-component-placement-03.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/1a7d83f66beadd476cb5e1f0939a74bb/a2ead/pcb-design-component-placement-03.png 259w", "/static/1a7d83f66beadd476cb5e1f0939a74bb/6b9fd/pcb-design-component-placement-03.png 518w", "/static/1a7d83f66beadd476cb5e1f0939a74bb/0a47e/pcb-design-component-placement-03.png 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/1a7d83f66beadd476cb5e1f0939a74bb/0a47e/pcb-design-component-placement-03.png",
            "alt": "High heatload components and panel edge",
            "title": "High heatload components and panel edge",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <ul>
      <li parentName="ul">{`High heatload components should be at least 20mm apart from each other to avoid large PCB partial heat load. `}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "35.13513513513513%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAACHVAAAh1QEEnLSdAAABXklEQVQY03WQv0sCYRzGbylqKEhTN3NoEqJfZi1t0eAf0H9RiHiHhxeF6alNBbk0tkSTNDVFixBGQ61eUXL3vqeW3o/uvUvt7htpPyDq4Zm+PB/48KUAABwHHBsAtMsjxM/j3SWUCqL0NM6FMT+D8gs499VsCOcXcXpKSgUb5RPKsd8AwO62n4sJKTaC6TEUd8n7qzgbQnGXlJxAtFtkPCLjwYwbJ7woOoQ2A8rNqQ1AAUCnWa0VImhjALM+nPBKtNvS6urFQf1wzSRE3glqsUGVGZVZH4oO470VQxZ6vjb1UilJmVmJHkdbk4gLIC4gJf21QgTzYZxfbhU5YXsunuE4nn1k/fXj9bZFeuSHL0UaD1bj3mphqyn+tFYxZcGUBVK9VcS7q7PS9XlZfxI73fbnj3qh4K/YAEQ3iK53rNeupjlaE0y9r/pN/gs7jmOZxCCGoakKkhRV7R9/zd4Bbr1IpCU77y8AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/954b8ae9f89c42e75ef451b4b13c742c/652f5/pcb-design-component-placement-04.webp 259w", "/static/954b8ae9f89c42e75ef451b4b13c742c/c29d2/pcb-design-component-placement-04.webp 518w", "/static/954b8ae9f89c42e75ef451b4b13c742c/e88ff/pcb-design-component-placement-04.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/954b8ae9f89c42e75ef451b4b13c742c/a2ead/pcb-design-component-placement-04.png 259w", "/static/954b8ae9f89c42e75ef451b4b13c742c/6b9fd/pcb-design-component-placement-04.png 518w", "/static/954b8ae9f89c42e75ef451b4b13c742c/0a47e/pcb-design-component-placement-04.png 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/954b8ae9f89c42e75ef451b4b13c742c/0a47e/pcb-design-component-placement-04.png",
            "alt": "High heatload component placement from each other",
            "title": "High heatload component placement from each other",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <h3 {...{
      "id": "through-hole-tht-component-placement"
    }}>{`Through-hole (THT) Component Placement`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`All polar THT components must be placed on its positive side, unless they are special structural components. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The distance between adjacent components must be at least 0.5mm. `}</p>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "422px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "33.204633204633204%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAABY0lEQVQY00WQy07CUBiE+7DGxBifQF9CjC5cqwsXaoLRGAUTsVUukULBUA69Uc6lpaengkR6gP4GGuOsZ/LNjGKaPQAYDb1PZ2S5A9F9cPsd7e3dcWzG2HK5TJLktlhMkgQApJRxHANAlmUAoJRLJR7HQwsZbNrQtZ/7g3bl/vjkdDBAQgjGWBzzne0tTVPXDN9/LpcBYLVaZVmmYIwBIGAUDYnn28Ks+JbZbLZs20YIcc4pIZqqBkEwm81aun5zfZWH12TYSEo5n8/Xff4qTadTjDGlNAiCNE3DMMQYI4QopVEUjcdj13WVjmFkAJ5jGTY2UVc0LpHReCw99zeilDqOc1Q4NHu9NJX1eu3i/AwAFouFlFIpPT1+fU1GjtWmk+pH5ftuX38pFo5PUL8vhOCcRzza292pVasAQAlR1df/w2LOASAMmDXEZIwTvx0Sb2BZnucRQvJFBOPcLaXkG38e/gXTMXP4ORQ3iQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/02e0c0a75813b4da8bde1768fc2cdcc2/652f5/pcb-design-component-placement-05.webp 259w", "/static/02e0c0a75813b4da8bde1768fc2cdcc2/fc0b3/pcb-design-component-placement-05.webp 422w"],
            "sizes": "(max-width: 422px) 100vw, 422px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/02e0c0a75813b4da8bde1768fc2cdcc2/a2ead/pcb-design-component-placement-05.png 259w", "/static/02e0c0a75813b4da8bde1768fc2cdcc2/fa5c1/pcb-design-component-placement-05.png 422w"],
            "sizes": "(max-width: 422px) 100vw, 422px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/02e0c0a75813b4da8bde1768fc2cdcc2/fa5c1/pcb-design-component-placement-05.png",
            "alt": "Distance between adjacent components",
            "title": "Distance between adjacent components",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <ul>
      <li parentName="ul">{`When SMD components are placed on the opposite side from the THT components, there should be at least 1mm distance between a THT component's leads and the opposite side's SMD component. The angle of elevation ofd the adjacent SMD component's height is at most 45 degrees. The measures are meant to meet the operating space of manual soldering, maintenance and inspection requirements. `}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.19305019305019%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAC4jAAAuIwF4pT92AAAB2klEQVQoz3VRz2sTURBeCnoSj978I/wHimcRKVTERmkV3RZtLSFtQSviQWis9CKIlSiKFLYBK8RYKrYNlNRYan8Sq9IWjMlusn27fewmu3H35c17TzYRycEMcxhm5vtm5htJtDCMsaZppVIJADDGCKF8Pk8Iae6RWoEBgDHGOXcJMADOOSGEc/4fMAAApYyxfwUKQbxruu3Te4s5SwjB6sDmHgkYZ0102LKRaVYc1/3t2eWKqqPvuz8Kuu641XxBxZbtkxoyDw3zECFUn8x5Kvnm9ZOJrc9LDQrH0DIfE0LUitiSZ/ezejCZC8FIdXXhXdVCwQoA0lhyu+/F0unhp6fk6Jl7r8LK2u3Zn2ejiZMX7sux1K2plQdvM70vl4fiGyMz2e5Y5kTH3Y7x5KCy/lXFUkT5cimWuaFs98ezvdM7XdGpzis9oZsDV8ORzvGZcxNz8+nVa5PzFyfT3Y8TIfm6PNjfMzoWer6ylTOa1GZUCOFvxKthybtzrBw54u1/qlHwfZ8zCrQG6qY/etwbbqPK5fqxTGKc/3UWCOfZB9a3lLO3rK29L2PDJ7TxMJ8LtJP+NXC00CcVn52ngVCs5Z9z6kGl4hY1zXGcRqas54y5R/jDQ3sz0ZD5Dy2W/daaFnTvAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/128b13b255a2aeab51c59c1e0ffe4e45/652f5/pcb-design-component-placement-06.webp 259w", "/static/128b13b255a2aeab51c59c1e0ffe4e45/c29d2/pcb-design-component-placement-06.webp 518w", "/static/128b13b255a2aeab51c59c1e0ffe4e45/e88ff/pcb-design-component-placement-06.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/128b13b255a2aeab51c59c1e0ffe4e45/a2ead/pcb-design-component-placement-06.png 259w", "/static/128b13b255a2aeab51c59c1e0ffe4e45/6b9fd/pcb-design-component-placement-06.png 518w", "/static/128b13b255a2aeab51c59c1e0ffe4e45/0a47e/pcb-design-component-placement-06.png 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/128b13b255a2aeab51c59c1e0ffe4e45/0a47e/pcb-design-component-placement-06.png",
            "alt": "Hand soldering requirements",
            "title": "Hand soldering requirements",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <ul>
      <li parentName="ul">{`When SMD components are placed on the same side as the THT components, the pad edge must be at least 3.0mm for parallel placements, and 1.0mm for vertical placements. `}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "52.50965250965252%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAACHVAAAh1QEEnLSdAAABt0lEQVQoz5WRXU8TQRSGT7FtIgrlM2q8MkgIF1qgaTAUopZUIE3kwsvOjmR2Z3e72wX/hFJali0BxRjTbfmand1NwX+os5saDPGC5MmbNzPznpwzB5IqS92dpMoGtUsAyoHyBOUJxYNIY/MPCgcl7GsgoDypegDUB+oBDUG/FtdqD7QrYfpFQeEZg+dr7ScWn7c6M9sXs+Yp0CClMhgg50CDnNX9oDnPP3lvqyfl6vGgEUDcSBQe1tkC+frYZHPK92m9M0N/gtZLqwzSW22QL7Om+546z3a8gn7yTj++b4Q3w5mqt0SOntbYIv320nBf0B9ALlIKg+Ht3vhOKIbRfoHsi7bV679txwMPGX7OdB9ZfrbWnbLYrNl9oJ2P1HwA3M7iVhE3C5K9gu03UvMVdlawXcAHS/hgGduvsZ3HTh45C6g1Lx0WKvvT+OiefPawygFosIhbZfSlKDXWUb2EGquouV7ZLaG9EqpvVOprlXpRapTR7qrULEmNTfQ59/EQlCBNmQhn5NMJ4o6R7jjpxBoZwUSko8KIB2OkM0ncIbn/22IqNfgPYcTtQ7HnVLznxN0B6v8J/wZ2ifJL/D72pwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/63c1c60b5096a61167abcf28c4b63749/652f5/pcb-design-component-placement-07.webp 259w", "/static/63c1c60b5096a61167abcf28c4b63749/c29d2/pcb-design-component-placement-07.webp 518w", "/static/63c1c60b5096a61167abcf28c4b63749/e88ff/pcb-design-component-placement-07.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/63c1c60b5096a61167abcf28c4b63749/a2ead/pcb-design-component-placement-07.png 259w", "/static/63c1c60b5096a61167abcf28c4b63749/6b9fd/pcb-design-component-placement-07.png 518w", "/static/63c1c60b5096a61167abcf28c4b63749/0a47e/pcb-design-component-placement-07.png 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/63c1c60b5096a61167abcf28c4b63749/0a47e/pcb-design-component-placement-07.png",
            "alt": "One side SMD component placement",
            "title": "One side SMD component placement",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <h3 {...{
      "id": "surface-mount-device-smd-component-placement"
    }}>{`Surface-mount Device (SMD) Component Placement`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Fine-pitch components (including BGA `}{`[insert link!!!]`}{`, 0.4mm, and 0.5mm QFP) are recommended to be placed on the same side. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The angle of elevation of SMD components should be less than 60 degrees to ease the soldering joint inspection process. `}</p>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "65.63706563706563%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAC4jAAAuIwF4pT92AAABI0lEQVQoz6VRy0rDQBTNd/gV/kD/wK/wKwyKRagk1boR6UrcigtxSFOL4EZBCKY1IZON9bFRWhHbkI4TbOZxJQ5oW0ir9XA298KZc+4ZDf4BbWLmX5hTzBiL4lhIOY84g+DA0r+Jldddp38WPJ/7j/GAZMupEbRRJQAsH/oL+uli+eKq3QEQ8pfOQkqa8opHdIesOAS/DRMyGHLBhRwhMCG/44zdLAH2wmT1mq659D7mACn7oDOc1TtHzkO5jpf2W4Vqs1B1dYR3G4GBmiZyt6ybiu1v1zwTuabl3XYjVceP+OCyXUT+TgMrGnawaeOSFWwgr3jcKtWy0aiH6yd++NRTZ2rTCpGcv/eT3guNXmcVNlZMRsYFY4JNdpZTWG6CnN/+BGbk3/v8TOT/AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/adc86da9d7179692d3ad0ec0af2fa7f2/652f5/pcb-design-component-placement-08.webp 259w", "/static/adc86da9d7179692d3ad0ec0af2fa7f2/c29d2/pcb-design-component-placement-08.webp 518w", "/static/adc86da9d7179692d3ad0ec0af2fa7f2/e88ff/pcb-design-component-placement-08.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/adc86da9d7179692d3ad0ec0af2fa7f2/a2ead/pcb-design-component-placement-08.png 259w", "/static/adc86da9d7179692d3ad0ec0af2fa7f2/6b9fd/pcb-design-component-placement-08.png 518w", "/static/adc86da9d7179692d3ad0ec0af2fa7f2/0a47e/pcb-design-component-placement-08.png 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/adc86da9d7179692d3ad0ec0af2fa7f2/0a47e/pcb-design-component-placement-08.png",
            "alt": "Angle of elevation requiremens for SMD",
            "title": "Angle of elevation requiremens for SMD",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <ul>
      <li parentName="ul">{`BGA components must be at least 3.5mm apart from the next component. `}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "27.7992277992278%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAACHVAAAh1QEEnLSdAAABDElEQVQY06WNzU7CQBzE9/1Q48VH8EnwIx6MD6BGL4jYqMSqJEpCjEgJMaZitGUNmCC2BaFs//2g23Z3TUEPxqO/zGEymcwg8Rs+FePfCCEYY2zm/oAY53HCE8ZDGhEHKKXpBGcRDWMaekA4S2ZVzw8IAAFwwKVxGqKfP0EnfgC2bdvG2L+sVA9O5SO5lC+e507OGk+tIQSBS8Ae+J4TR1E0/UDN7mj/Rl/PX++WH2W1t3mhLm2VFtaOM9nC3Io0vyplsoXFjeLyduVQaUuNt52rh72ymqviT5ig7tC91c16u6+89u9axn1n8NyzNYPohqMZjmamevkYN99HCrZq2FKwWcdmDVsQUCT+wRfC7kNG/hwWIQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e52ea4e01855b6dcc49ad15fc7482a13/652f5/pcb-design-component-placement-09.webp 259w", "/static/e52ea4e01855b6dcc49ad15fc7482a13/c29d2/pcb-design-component-placement-09.webp 518w", "/static/e52ea4e01855b6dcc49ad15fc7482a13/e88ff/pcb-design-component-placement-09.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e52ea4e01855b6dcc49ad15fc7482a13/a2ead/pcb-design-component-placement-09.png 259w", "/static/e52ea4e01855b6dcc49ad15fc7482a13/6b9fd/pcb-design-component-placement-09.png 518w", "/static/e52ea4e01855b6dcc49ad15fc7482a13/0a47e/pcb-design-component-placement-09.png 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/e52ea4e01855b6dcc49ad15fc7482a13/0a47e/pcb-design-component-placement-09.png",
            "alt": "BGA component placement",
            "title": "BGA component placement",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`QFN components must be at least 2.0mm apart from the next component. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A surface-mount module must be at least 2.0mm apart from the next component. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`SMD with 'wings' or multiple leaded sides, such as SOPs, QFJs, and QFPs should not be positioned on top of each other. `}</p>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "501px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "84.16988416988417%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAACHVAAAh1QEEnLSdAAACDElEQVQ4y2NQyJnPl7lUKGuJYCYSysLBRhIUylzCIJC5hCF5NWPKKgYyEMiAlFVMqSsZwYghZQUIpa5iSF0NJmEMsDgjTBlUsSBYM4gFQivEitbLlG6UyloknblAOnO+TOZ82Yx5MlkLZUrWS5ZsYEqDKIMhFM0pKxJnH6pYdeZoTcztIseblT43qnwflzndLnWvX7ova/EJrsxVEPuxaw6ctC9pzqGj7TmvpuZcq/C5Ue7xqDXi1rSK3Onbwmcc5sxcyZCC2+awaYcy5x48Mrn2+eaZD8tdXhTbPJ2YeXXzouJZ22PnHOME2bwSl83L4+ccKll67MTEymer+x+Vu7wosX3Sl3JjzfS6hbsyFp/kwq85esaBgoWHj00of76yG6q5N/nGqimVc3ckzz9OwM+2bbu8uncc7Kt4vqoXbvO1VVMjutY7du9lz1iF28/JK+JnHylZevTYpMrna/rAmm2e9KVeXzujdsHO9EUn8du8PHTagYx5Bw/3VTxe0XO33O1xif2D3pSrKycXzdoWM+cogdD27NkdMf3ghbqon3WOr6uc31Y5faxxftHglzZ5s9+kAxwZeDX7TtgbP+eod+FEv4wm/9wOv9xO/6xWn5yO6Gn7w6YdZM/E4+fUlQyxSxliljAkr2FIWYdAyWsZYpYyxC5B0YmpmSl9FXP6KubUFegILI6iE4vNpCAAURmunmzpLnsAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/fa691a19e52e8f44b76b68413192432d/652f5/pcb-design-component-placement-10.webp 259w", "/static/fa691a19e52e8f44b76b68413192432d/52d01/pcb-design-component-placement-10.webp 501w"],
            "sizes": "(max-width: 501px) 100vw, 501px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/fa691a19e52e8f44b76b68413192432d/a2ead/pcb-design-component-placement-10.png 259w", "/static/fa691a19e52e8f44b76b68413192432d/55811/pcb-design-component-placement-10.png 501w"],
            "sizes": "(max-width: 501px) 100vw, 501px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/fa691a19e52e8f44b76b68413192432d/55811/pcb-design-component-placement-10.png",
            "alt": "2 SOICs stacked design (NOT RECOMMENDED)",
            "title": "2 SOICs stacked design (NOT RECOMMENDED)",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`THT and SMD components can be placed stacked on different layers, as long as the THT soldering does not interfere the SMD pad and the printed solder paste. `}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "96.13899613899613%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAACHVAAAh1QEEnLSdAAADDUlEQVQ4y32S+0/TUBTHbzs0aNSgifgTQYnx8ZuJir+ixugPEhVfQWNgXdsh46XIuvW5Pra1ZWMrG2VMEdrJ+sIhElE0in+aqQx5qcknN/ece7+553zPBfuevQ4gZiBo/B+4d74haBzAKvUMYjY8ew0CSAWEFwHu/cbdZPveBw57l6W1s/SneibswUgFBIKmH2DOHyDMgTF7OxBmN+BOh/q1XfoCUNu/hrtw0KyLYXxTjDsAdQDi7cG5NfH9trbun26Jew0/DloAtSHMAYh9uN+9QloXY9VLv7lIVNvj1ZMvnUDYa+zzdoibnlebR5YuJFabht53EpOjYvYOXc5llfEkl0nzaiqRkaWV6htMMbpZfTRdgFBrS3xi0L6R/X6vuN4W/9jPZVxdecpO81JaZGiB4wSW5TluJqd0C7MP6JmINLW756PDS0cGF/eF3dbI3KnI7H3ZK6REORZVaVKl4mosmqNjk85aAPsAehyAuTsNCzlQyAKo0/TcaokYj7RVPS3KxJhKxRUqrhJjGhsvLf24SpnXiPJOw+pu2wDxHjKld0XlxXQtK6eSNJni6BRLpxhaz2ZKSz/4fEmdyMPbe94QQ5gNULc5snB++O3j/Eo+K3MJThR5QeR5UTBMQ3M+Hx9yj/WZe8re/CQw6oEnXtfEp8lsOpFgRTEhCgleSBhlPWPU9vctg14Xwpy/v9w2ZF4nyk+1lfx4WmAZiecknuM5Ni8ny8s/H/GzT9jpf/Qc9LqYGaM4PqTXcmlJJqJ1w2KxAktOLX6LZmYYRYP2fs+Nsg/2WYdw86G2qiuCHB1VKVKhSZUYm+TI2eX1xv4aQCywt+yN1P6w3YgvtLO1vCyJFJlkmCTDSDSV48gRzW4dXjg3Mv+3sjF/VHep0nxxvCM2l1WSskApIiuLTFpgCymqm9EJuVjQcrtGVQG453sY8k4Pm51U+cyoPaKWI9LUQFIfSOr9kv5KnrrJLbQMmOdfGAB1/WnhHhysgIaeORi14ZAFhyyA2KDX9lfU3QUUsgHiAMTZuAmjdqBn/hfextoteWoFtgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/8a3b3c014677e35f5bd53dfa17f5c5ef/652f5/pcb-design-component-placement-11.webp 259w", "/static/8a3b3c014677e35f5bd53dfa17f5c5ef/c29d2/pcb-design-component-placement-11.webp 518w", "/static/8a3b3c014677e35f5bd53dfa17f5c5ef/e88ff/pcb-design-component-placement-11.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/8a3b3c014677e35f5bd53dfa17f5c5ef/a2ead/pcb-design-component-placement-11.png 259w", "/static/8a3b3c014677e35f5bd53dfa17f5c5ef/6b9fd/pcb-design-component-placement-11.png 518w", "/static/8a3b3c014677e35f5bd53dfa17f5c5ef/0a47e/pcb-design-component-placement-11.png 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/8a3b3c014677e35f5bd53dfa17f5c5ef/0a47e/pcb-design-component-placement-11.png",
            "alt": "DIP-8 (THT) and SOIC-8 (SMD) compatible stacked design",
            "title": "DIP-8 (THT) and SOIC-8 (SMD) compatible stacked design",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The minimum gap of neighboring SMD components with the same variety is 0.5mm.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The placement of neighboring SMD components of different kinds should have a maximum elevation angle of 60 degrees. `}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "silkscreen-design"
    }}>{`Silkscreen Design`}</h2>
    <ul>
      <li parentName="ul">{`Annotations written on silkscreen must be written in two direction only: `}<strong parentName="li">{`left to right`}</strong>{` or `}<strong parentName="li">{`bottom to top`}</strong>{`.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACOUlEQVQ4y61VTU8TURSdiLbTWttgNGiixYVdkWgi0/kqOGKaUFFqMTREoSilM/0AM7YxpZ1SF0ZlY2JgAfIzXJgYjchKXehKEhfu/CPHvNfpMJ22BrSLm/fuzdzz3rnv3DvM8ZwMb1ZCcz2SEajZY2Q9qDHNTTNx6kUC8We3qX9YsBbAY6qIwUfXUN+eh7GVwnldgUsT/x3QR5xFHsm1KSSeJ+jed0iwNsqerIT663lUNufAOikT31xb9t0A3ZqI08uj+LW7ih8fDZxaGgGrSW1gPtPs4B0BSTIB+f6hgs/vyjhpA/TaQN2FEfQVIpbv/RvlEzkZYWMcXHWc1s+ibCb702EMzVxCdPoy2LzcVmPyPePUYV9GoGaPkUS3yiNUnkS0PothTYErK9Kae7pRbgL4cvsn74PKYNIcQtUEbm7oOFueAKsKCBQi8OdlKi9ijRs6OsIOSIx8GCpdh2DEoL2cxu/dGorr92j869vH+PSmCNGIYXT1RkN61suZAE7KRD5nHl5FUFcg1WJ4un0fY08maLy0cRfLr2ZwTldwsTTWoOzJyThakOFSeVp8vsOjkNcmNyIHMQ842us0eYEDsxCmkrMo96si+FkOg+VbCBaj+PZ+BV8csrF3UyAfsQ7z5yPUWh5lYFFAPHkFsXUdQ9U4fu5UsbdTaxV2h6nTzWcCmoSBjIjgyiT6lxTUtlKobKbaW++gvdxQuwRW5cGkw0iu3fnP4WDewqVJuFDsxfhy6LAnA7aXv4A/0kC+GCwlLvEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/776b06d3e63764f3b9b5f295a5e69d0a/652f5/pcb-design-silkscreen-01.webp 259w", "/static/776b06d3e63764f3b9b5f295a5e69d0a/c29d2/pcb-design-silkscreen-01.webp 518w", "/static/776b06d3e63764f3b9b5f295a5e69d0a/e88ff/pcb-design-silkscreen-01.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/776b06d3e63764f3b9b5f295a5e69d0a/a2ead/pcb-design-silkscreen-01.png 259w", "/static/776b06d3e63764f3b9b5f295a5e69d0a/6b9fd/pcb-design-silkscreen-01.png 518w", "/static/776b06d3e63764f3b9b5f295a5e69d0a/0a47e/pcb-design-silkscreen-01.png 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/776b06d3e63764f3b9b5f295a5e69d0a/0a47e/pcb-design-silkscreen-01.png",
            "alt": "Text on silkscreen",
            "title": "Text on silkscreen",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Place chip annotation on the first leg of a chip component (i.e. the top-left leg or bottom-left leg). `}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAPoAAAD6AG1e1JrAAACXklEQVQ4y42T7U/aUBjFL0yg9F61UyNqYajDKWOKOpDe29Ii4GAGIyjKJG5OJ70tpdSXuSzLlmVmc2Zu2df9uQvuNVGKyfnwfPnl3Oec5wKokf/FqAlGTbAUQ/WPKEFaS/CKwN+JpbinIcc+7678eBX99NRTx92WjEyJNURGIwzFbWGkEY9OQnvCMV3+0CxvVxV3IczkJ5nSdN9uYshI+kwZ2cAMxbwpPzlbXXtB+Mgw6PU6AAD97FRivGwtFc9LPbrItoVVPGIpla8bW0e5YGwUcF4nAGCAjUoTlZN8qQNM8XBTrl6UK0bGPxsAPZ6Bbu7WEDe1MLZxdOms2cI+U17+uPq4schLd4EPPhTivTOj40oof5jNnhbaOv8OXCNuVWB0AhsSrAnuZ/PefQE2JI9GPCq2q+qfKGYpgboIDRHqrQrhdSW3gS+36NYIor8GEd0QRjpx1YS5N7niealwVlw5KxbPS+GTrKsmoM6wRtwaJoeZ9cOlysvcxkm+epx7YKVcFHeGWwubybG12XBsNCpPRqV792NBfnOOaUiI3gBGZrJrngec1zGIwACEQR+TmmjlfyW5K8+mhG3KzuBtBwBOZ+tA45HoIJ5i6gTaO7e+BxX6NIzCPgBdDo4BnBe4Ha54ADWTnZxVAVrplJ6WpFBvZNg/F+Cj/pHISP/qNGvawkgXPfvxsXfr5ind2yH5+mLVyFTMzFY9PW0oLs02baSLzP7CnbdF/X1t5yC7/n1z+0u5elF+/m1z5vWjrpqAdNudWYq5hjJkpn266D9I8ZYSOEj5LaXfkLz0mgv7CVFjEO0ONRRLAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/26a04b4c302a140350678403a14dcabb/652f5/pcb-design-silkscreen-02.webp 259w", "/static/26a04b4c302a140350678403a14dcabb/c29d2/pcb-design-silkscreen-02.webp 518w", "/static/26a04b4c302a140350678403a14dcabb/e88ff/pcb-design-silkscreen-02.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/26a04b4c302a140350678403a14dcabb/a2ead/pcb-design-silkscreen-02.png 259w", "/static/26a04b4c302a140350678403a14dcabb/6b9fd/pcb-design-silkscreen-02.png 518w", "/static/26a04b4c302a140350678403a14dcabb/0a47e/pcb-design-silkscreen-02.png 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/26a04b4c302a140350678403a14dcabb/0a47e/pcb-design-silkscreen-02.png",
            "alt": "Chip annotation on silkscreen",
            "title": "Chip annotation on silkscreen",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<strong parentName="p">{`font width and sizes`}</strong>{` used should be `}<strong parentName="p">{`4/20mil, 5/25mil, and 6/30mil`}</strong>{`. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The distance between different annotations or silkscreen elements should be greater than 5mil.  `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The silkscreen text should be placed close to the corresponding elements to identify them clearly. `}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`When component density is high, annotation could be placed `}<strong parentName="p">{`using boxes or arrows`}</strong>{` to indicate which component the text belongs to. `}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`When the PCB requires high servicability, place annotation `}<strong parentName="p">{`under the components`}</strong>{`. This will deem them invisible once the components are soldered. `}</p>
          </li>
        </ul>
      </li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Silkscreen should contain information about the `}<strong parentName="p">{`mounting hole position and dimensions`}</strong>{`. This includes screw holes, where the `}<strong parentName="p">{`type and length of the screw`}</strong>{` should also be noted. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The polarity mark (i.e. signal direction) should always be indicated. Additional symbols for polarity indication could be added, since wrong polarity placement is one of the most common reasons for part failure. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Polar components shall placed with the same orientation to reduce human error in manufacturing and quality control. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`There should be no silkscreen markings on top of a pad or exposed part. Doing so will reduce the solderability of the pad and will reduce readability alike. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`No silkscreen should be present on top of high-speed signal cables to prevent it from negatively affecting the impedance of the cables. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If there are `}<strong parentName="p">{`pads enclosed by packaged components`}</strong>{` such as BGA or QFN chips, the silkscreen should have at least `}<strong parentName="p">{`6mil clearance from the pads`}</strong>{`. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Connector (such as USB) directions should be clearly indicated on the silkscreen and pin numbers should also be present. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Board name, manufacturing date, version number, and other identifying information should be placed at the corner, with enough clearance from the edge of the board and other components. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Quality verification signs should be added according to related requirements and warning signs for high temperature or pressure should also be added according to your needs. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Company logo, tracking barcodes, QR codes, anti-static markings, etc. are absolutely optional but can be placed at the corner with the other identifying information. `}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      